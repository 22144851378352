/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */

const directions = {
  DOWN: { key: 'down', transform: 'rotate(0deg)' },
  LEFT: { key: 'left', transform: 'rotate(90deg)' },
  UP: { key: 'up', transform: 'rotate(180deg)' },
  RIGHT: { key: 'right', transform: 'rotate(270deg)' },
}

const getDirection = (direction) => {
  if (direction === directions.DOWN.key) {
    return { transform: directions.DOWN.transform }
  }
  if (direction === directions.LEFT.key) {
    return { transform: directions.LEFT.transform }
  }
  if (direction === directions.UP.key) {
    return { transform: directions.UP.transform }
  }
  if (direction === directions.RIGHT.key) {
    return { transform: directions.RIGHT.transform }
  }
  return undefined
}

const Arrow = ({ direction: _direction = directions.DOWN.key }) => {
  const direction = getDirection(_direction)
  return (
    <svg className="icon__arrow" style={direction} viewBox="0 0 24 24" focusable="false" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></svg>
  )
}

export default Arrow
