/* eslint-disable max-len */
const name = 'Germany'
export default {
  name,
  logo: (
    <svg viewBox="0 0 640 480">
      <title>{name}</title>
      <path fill="#ffce00" d="M0 320h640v160H0z" />
      <path d="M0 0h640v160H0z" />
      <path fill="#d00" d="M0 160h640v160H0z" />
    </svg>
  ),
}
