/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import Link from 'next/link'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
// import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {
  no, se, dk, be, uk, us, it, es, de,
} from 'icons/flags'
import LanguageModal from 'components/LanguageModal/LanguageModal'
import Arrow from 'icons/icons/Arrow'
import LinkWrapper from 'components/LinkWrapper/LinkWrapper'
import { NO_LONGER_ACTIVE_PAGE } from 'pages/no-longer-active.page'
// import SwitchLink from 'components/SwitchLink/SwitchLink'

dayjs.extend(isBetween)

const countries = [
  {
    href: null,
    name: se.name,
    logo: se.logo,
    short: 'se',
    disabled: false,
  },
  {
    href: null,
    name: no.name,
    logo: no.logo,
    short: 'no',
    disabled: true,
  },
  {
    href: null,
    name: dk.name,
    logo: dk.logo,
    short: 'dk',
    disabled: true,
  },
  {
    href: null,
    name: be.name,
    logo: be.logo,
    short: 'be',
    disabled: true,
  },
  {
    href: null,
    name: de.name,
    logo: de.logo,
    short: 'de',
    disabled: true,
  },
  {
    href: null,
    name: uk.name,
    logo: uk.logo,
    short: 'uk',
    disabled: true,
  },
  {
    href: null,
    name: us.name,
    logo: us.logo,
    short: 'us',
    disabled: true,
  },
  {
    href: null,
    name: it.name,
    logo: it.logo,
    short: 'it',
    disabled: true,
  },
  {
    href: null,
    name: es.name,
    logo: es.logo,
    short: 'es',
    disabled: true,
  },
]

const links = {
  sitemap: [
    {
      name: 'Om Oss',
      href: '/se/om-oss',
      nextLink: true,
    },
    {
      name: 'Kontakta Oss',
      href: '/se/kontakta-oss',
      nextLink: true,
    },
    {
      name: 'Våra Butiker',
      href: '/se/vara-butiker',
      nextLink: true,
    },
    {
      name: 'Lediga Tjänster',
      href: '/se/lediga-tjanster',
      nextLink: true,
    },
    {
      name: 'Analytics',
      href: '/se/analytics',
      nextLink: true,
    },
  ],

  information: [
    {
      name: 'Artiklar',
      href: '/se/articles',
      nextLink: true,
    },
    {
      name: 'Press',
      href: '/se/press',
      nextLink: true,
    },
    {
      name: 'Cookies',
      href: '/se/cookies',
      nextLink: true,
    },
    {
      name: 'Integritetspolicy',
      href: '/se/integritetspolicy',
      nextLink: true,
    },
  ],
}

const Footer = (/* { categories = [], salesPeriods = [] } */) => {
  // const current = dayjs()
  // const router = useRouter()

  const innerWidth = useSelector((state) => state.main.innerWidth)
  const [countriesIsExpanded, setCountriesIsExpanded] = useState(false)
  // const [categoriesIsExpanded, setCategoriesIsExpanded] = useState(false)
  const [sitemapIsExpanded, setSitemapIsExpanded] = useState(false)
  // const [salesperiodIsExpanded, setSalesperiodIsExpanded] = useState(false)
  const [showLanguagePopUp, setShowLanguagePopUp] = useState(false)
  const [clickedFlag, setClickedFlag] = useState('')

  useEffect(() => {
    if (innerWidth < 900) {
      setCountriesIsExpanded(false)
      // setCategoriesIsExpanded(false)
      setSitemapIsExpanded(false)
      // setSalesperiodIsExpanded(false)
      return
    }
    setCountriesIsExpanded(true)
    // setCategoriesIsExpanded(true)
    setSitemapIsExpanded(true)
    // setSalesperiodIsExpanded(true)
  }, [innerWidth])

  // const onShoppingLinkPressed = () => {
  //   if (router.pathname === '/se/category/[filterLevel]/[sortOrder]') {
  //     window.scrollTo({ top: 0, behavior: 'auto' })
  //   }
  // }

  // const onSalesPeriodLinkPressed = () => {
  //   if (router.pathname === '/se/rea/[slug]') {
  //     window.scrollTo({ top: 0, behavior: 'auto' })
  //   }
  // }

  const handleCountriesAccordion = () => {
    setCountriesIsExpanded(!countriesIsExpanded)
  }

  // const handleCategoriesAccordion = () => {
  //   setCategoriesIsExpanded(!categoriesIsExpanded)
  // }

  const handleSitemapAccordion = () => {
    setSitemapIsExpanded(!sitemapIsExpanded)
  }

  // const handleSalesperiodAccordion = () => {
  //   setSalesperiodIsExpanded(!salesperiodIsExpanded)
  // }

  // const renderDaysLeft = (x) => {
  //   const getText = (daysLeft) => {
  //     const days = daysLeft > 1 ? 'dagar' : 'dag'
  //     return `${daysLeft} ${days} kvar`
  //   }

  //   const { startdate, enddate } = x.salesPeriodParameters

  //   if (dayjs(current).isBetween(startdate, enddate, 'second')) {
  //     return <span className="currently">Pågår just nu!</span>
  //   }

  //   const hoursLeft = dayjs(startdate, 'YYYY-MM-DD HH:mm:ss').diff(current, 'seconds')
  //   const daysLeft = Math.ceil(hoursLeft / 24 / 60 / 60)

  //   return <span>{getText(daysLeft)}</span>
  // }

  const triggerModal = (name) => () => {
    if (name === 'Sweden') return
    setClickedFlag(name)
    setShowLanguagePopUp(true)
  }

  return (
    <footer id="footer">
      <div className="container">

        <div>

          <div className="footer-left-wrap">
            <div className="footer-brand">

              <svg className="jiroy-logo" viewBox="0 0 499.2 116.1">

                <path d="M74.9,26.1v57.1H61.7V26.1H74.9z" />
                <path d="M93,26.1h13.1v5.1c2.4-2.5,4.6-4.3,6.4-5.2c1.9-1,4.2-1.5,6.8-1.5c3.5,0,7.1,1.1,10.9,3.4l-6,12.1 c-2.5-1.8-4.9-2.7-7.3-2.7c-7.2,0-10.8,5.4-10.8,16.3v29.6H92.9V26.1L93,26.1L93,26.1z" />
                <path d="M134.1,54.2c0-8.2,2.9-15.3,8.8-21c5.9-5.8,13.1-8.7,21.6-8.7c8.5,0,15.7,2.9,21.7,8.7c5.9,5.8,8.8,13,8.8,21.4 c0,8.6-3,15.7-8.8,21.5c-5.9,5.7-13.2,8.6-21.9,8.6c-8.6,0-15.7-2.9-21.5-8.8C137,70.2,134.1,63,134.1,54.2z M147.6,54.5 c0,5.7,1.5,10.2,4.6,13.5c3.1,3.4,7.2,5,12.4,5s9.3-1.7,12.4-5s4.6-7.8,4.6-13.3s-1.5-10-4.6-13.3c-3.1-3.4-7.2-5-12.4-5 c-5,0-9.1,1.7-12.2,5C149.2,44.8,147.6,49.2,147.6,54.5z" />
                <path d="M259.8,76.6c0-2.2,0.8-4.1,2.4-5.7c1.6-1.6,3.5-2.4,5.7-2.4s4.1,0.8,5.7,2.4c1.6,1.6,2.4,3.5,2.4,5.7 c0,2.3-0.8,4.2-2.4,5.8s-3.5,2.3-5.7,2.3c-2.3,0-4.2-0.8-5.8-2.3S259.8,79,259.8,76.6z" />
                <path d="M345.1,54.2c0-8.2,2.9-15.3,8.8-21c5.9-5.8,13.1-8.7,21.6-8.7s15.7,2.9,21.7,8.7c5.9,5.8,8.8,13,8.8,21.4 c0,8.6-2.9,15.7-8.8,21.5c-5.9,5.7-13.2,8.6-21.9,8.6c-8.6,0-15.7-2.9-21.5-8.8C348,70.2,345.1,63,345.1,54.2z M358.6,54.5 c0,5.7,1.5,10.2,4.6,13.5c3.1,3.4,7.2,5,12.4,5c5.2,0,9.3-1.7,12.4-5s4.6-7.8,4.6-13.3s-1.5-10-4.6-13.3c-3.1-3.4-7.2-5-12.4-5 c-5,0-9.1,1.7-12.2,5C360.1,44.8,358.6,49.2,358.6,54.5z" />
                <path d="M418.9,26.1h13.2v5.3c2.5-2.7,4.7-4.5,6.5-5.4c1.9-1,4.3-1.5,7.2-1.5c6.4,0,11.5,2.8,15.3,8.4 c4.1-5.6,9.7-8.4,16.8-8.4c12.9,0,19.3,7.8,19.3,23.4v35.3H484V51.5c0-5.5-0.7-9.3-2-11.6c-1.4-2.3-3.6-3.5-6.7-3.5 c-3.6,0-6.3,1.4-7.9,4.1c-1.7,2.7-2.5,7.1-2.5,13.2v29.5h-13.2V51.7c0-10.2-2.9-15.2-8.8-15.2c-3.7,0-6.4,1.4-8.1,4.2 c-1.7,2.8-2.5,7.1-2.5,13.1v29.5h-13.2V26.1C419.1,26.1,418.9,26.1,418.9,26.1z" />
                <polygon points="224,100.5 262.4,26.1 247.5,26.1 231.2,58.9 213.8,26.1 198.5,26.1 224.2,72.6 209.2,100.5 61.7,100.5 61.7,113.7 202.2,113.7 217.2,113.7 497.2,113.7 497.2,100.5 " />

                <path d="M30,80.6c0,7.7-0.8,13.1-2.5,16.2c-1.6,3.1-4.4,4.6-8.4,4.6c-2.9,0-6-1.1-9.3-3.3l-6.7,11.6 c5,3,10.3,4.5,16,4.5c7.5,0,13.6-2.7,18.2-8c2.3-2.7,4-6,5-9.9c1-3.4,1.5-8.7,1.5-15.6V25.8H30V80.6z" />

                <path d="M330.2,71c-2.4,1.4-5.2,2.1-8.4,2.1c-5,0-9-1.7-12.2-5.2c-3.2-3.4-4.7-7.8-4.7-13.2c0-5.2,1.6-9.6,4.9-13.1 s7.4-5.2,12.4-5.2c3.2,0,5.9,0.7,8.3,2.1c0.5,0.3,1.1,0.7,1.7,1.1l5.8-11.4c-4.8-2.5-9.9-3.7-15.3-3.7c-8.8,0-16.1,2.9-22.1,8.8 s-9,13-9,21.6c0,8.5,3,15.6,8.9,21.4c5.9,5.7,13.3,8.6,22.1,8.6c4.8,0,9.7-1.1,14.5-3.4l-5.6-11.1C330.9,70.6,330.5,70.8,330.2,71z" />

                <path d="M487.2,3.1c4.1,0,7.2,3.1,7.2,7.2s-3.1,7.2-7.2,7.2s-7.2-3.1-7.2-7.2S483.1,3.1,487.2,3.1z M487.2,4.3 c-3.4,0-6,2.6-6,6s2.6,6,6,6s6-2.6,6-6S490.7,4.3,487.2,4.3z M488.7,14.2l-2.6-3.3v3.3H485V6.3h1.4c0.6,0,3,0,3,2.2 c0,1.2-0.8,2.1-2,2.2l2.7,3.4h-1.4V14.2z M486.2,9.9h0.5c0.7,0,1.7-0.1,1.7-1.2c0-1.2-1-1.3-1.8-1.3h-0.4V9.9z" />
              </svg>

              <div>

                <p>
                  Hundratals butiker, tusentals varumärken och flera miljoner produkter på ett och samma ställe - Det är vad vi kallar smart shopping!
                  {/* {' '}
                  <Link href="/se/om-oss"><a>smart shopping!</a></Link> */}
                </p>

                {/* <ul className="nav-list social">
                  <li className="facebook">

                    <a
                      href="https://www.facebook.com/jiroyshopping"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      title="Facebook"
                      aria-label="Facebook"
                    >
                      <svg viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
                    </a>

                  </li>

                  <li className="instagram">
                    <a
                      href="https://www.instagram.com/jiroy_shopping/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      title="Instagram"
                      aria-label="Instagram"
                    >
                      <svg viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                    </a>
                  </li>

                  <li className="linkedin">
                    <a
                      href="https://www.linkedin.com/company/jiroy/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      title="Linkedin"
                      aria-label="Linkedin"
                    >
                      <svg viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
                    </a>
                  </li>
                </ul> */}

              </div>

            </div>
          </div>

          <div className="footer-accordions">
            <Accordion expanded={countriesIsExpanded} onChange={handleCountriesAccordion} square>
              <AccordionSummary data-testid="footer-accordion-information" expandIcon={<Arrow />} aria-controls="panel0d-content">
                Internationellt
              </AccordionSummary>
              <AccordionDetails>
                <ul className="links countries">
                  {countries.slice(0, 5).map(({
                    name, logo, href, disabled,
                  }) => (
                    <li title={name} key={name} style={{ cursor: 'pointer' }}>
                      <LinkWrapper href={NO_LONGER_ACTIVE_PAGE} rel="nofollow noreferrer">
                        {logo}
                        <b>{name}</b>
                      </LinkWrapper>
                    </li>
                  ))}
                </ul>
                <ul className="links countries">
                  {countries.slice(-4).map(({
                    name, logo, href, disabled,
                  }) => (
                    <li title={name} key={name} style={{ cursor: 'pointer' }}>
                      <LinkWrapper href={NO_LONGER_ACTIVE_PAGE} rel="nofollow noreferrer">
                        {logo}
                        <b>{name}</b>
                      </LinkWrapper>
                    </li>
                  ))}
                </ul>

              </AccordionDetails>
            </Accordion>

            <Accordion expanded={sitemapIsExpanded} onChange={handleSitemapAccordion} square>
              <AccordionSummary data-testid="footer-accordion-information" expandIcon={<Arrow />} aria-controls="panel1d-content">
                Företag
              </AccordionSummary>
              <AccordionDetails>
                <ul className="links">
                  {links.sitemap.map((x) => (
                    <li data-testid={`footer-accordion-option-${x.href}`} title={x.name} key={x.name}>
                      {!x.nextLink && <a href={x.href}>{x.name}</a>}
                      {x.nextLink && (
                        <Link href={x.href}>
                          <a>{x.name}</a>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
                <ul className="links">
                  {links.information.map((x) => (
                    <li data-testid={`footer-accordion-option-${x.href}`} title={x.name} key={x.name}>
                      {!x.nextLink && <a href={x.href}>{x.name}</a>}
                      {x.nextLink && (
                        <Link href={x.href}>
                          <a>{x.name}</a>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>

              </AccordionDetails>
            </Accordion>

            {/* <Accordion expanded={salesperiodIsExpanded} onChange={handleSalesperiodAccordion} square>
              <AccordionSummary data-testid="footer-accordion-sale-periods" expandIcon={<Arrow />} aria-controls="panel2d-content">
                Reaperioder
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <ul className="links">
                    {salesPeriods.slice(0, 9).map((x) => (
                      <li title={x.title} key={x.title} onClick={onSalesPeriodLinkPressed}>
                        <Link href={`/se/rea/${x.slug}`}>
                          <a data-testid={`footer-accordion-option-${x.slug}`}>
                            {x.title}
                            {renderDaysLeft(x)}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion> */}

            {/* <Accordion expanded={categoriesIsExpanded} onChange={handleCategoriesAccordion} square>
              <AccordionSummary data-testid="footer-accordion-categories" expandIcon={<Arrow />} aria-controls="panel3d-content">
                Kategorier
              </AccordionSummary>
              <AccordionDetails>
                <ul className="links">
                  {(categories).map((category) => {
                    // eslint-disable-next-line camelcase
                    const { key, href } = category
                    return (
                      <li title={key} key={key} onClick={onShoppingLinkPressed}>
                        <SwitchLink href={href}>
                          {key}
                        </SwitchLink>
                      </li>
                    )
                  })}
                </ul>
              </AccordionDetails>
            </Accordion> */}
          </div>
        </div>

        <div>

          <p>
            &#169;
            {' '}
            {new Date().getFullYear()}
            {' '}
            <a href="https://www.allabolag.se/5594840406/jiroy-technology-ab" target="_blank" rel="noopener noreferrer nofollow">Jiroy Technology AB</a>
            . All rights reserved.
          </p>

        </div>

      </div>
      <LanguageModal isOpen={showLanguagePopUp} clickedFlag={clickedFlag} onModalClose={() => { setShowLanguagePopUp(false) }} />
    </footer>
  )
}

export default Footer
