import { Modal } from '@material-ui/core'
import { useState } from 'react'
import { validateEmail } from '../../helper'
import { Mail } from '../../services/main'

const LanguageModal = ({ isOpen, onModalClose, clickedFlag }) => {
  const [subscriptionIsVerified, setSubscriptionIsVerified] = useState(false)
  const [model, setModel] = useState({
    name: { value: '', error: false, errorMessage: '' },
    email: { value: '', error: false, errorMessage: '' },
  })

  const phrases = [
    { country: 'Sweden', text: 'Jiroy will soon be available in' },
    { country: 'Norway', text: 'Jiroy er snart tilgjengelig i Norge! Meld deg på så gir vi deg beskjed!' },
    { country: 'Denmark', text: 'Jiroy er snart tilgængelig i Danmark! Tilmeld dig og vi giver dig besked!' },
    { country: 'Belgium', text: 'Jiroy is binnenkort beschikbaar in België! Meld je aan en we laten het je weten!' },
    { country: 'Germany', text: 'Jiroy wird bald auch in Deutschland erhältlich sein! Melden Sie sich an und wir informieren Sie!' },
    { country: 'United Kingdom', text: 'Jiroy will soon be available in United Kingdom! Sign up and we will let you know!' },
    { country: 'United States', text: 'Jiroy will soon be available in United States! Sign up and we will let you know!' },
    { country: 'Italy', text: 'Jiroy sarà presto disponibile in Italia! Iscriviti e ti faremo sapere!' },
    { country: 'Spain', text: 'Jiroy pronto estará disponible en España! ¡Regístrate y te informaremos!' },
  ]
  const getLanguageBasedText = () => phrases.find((x) => x.country === clickedFlag)?.text

  const onModalCloseClick = () => {
    setModel({
      name: { value: '', error: false, errorMessage: '' },
      email: { value: '', error: false, errorMessage: '' },
    })
    setSubscriptionIsVerified(false)
    onModalClose?.()
  }

  const handleInputOnChange = (e) => {
    e.preventDefault()
    setModel({
      ...model,
      name: { value: clickedFlag, error: false, errorMessage: '' },
      email: { value: e.target.value, error: false, errorMessage: '' },
    })
  }

  const onSendInterest = async () => {
    const { status } = await Mail.v1.sendAnalyticsInterest(model)

    setModel({
      name: { value: clickedFlag, error: false, errorMessage: '' },
      email: { value: '', error: false, errorMessage: '' },
    })
    if (status === 500) {
      setModel({
        ...model,
        email: { value: model.email.value, error: true, errorMessage: 'E-postadressen är redan registrerad hos oss' },
      })
    } else {
      setSubscriptionIsVerified(true)
    }
  }

  const subscribe = async (event) => {
    event.preventDefault()
    const emailHasError = !validateEmail(model.email.value)
    setModel({
      ...model,
      name: { value: clickedFlag, error: false, errorMessage: '' },
      email: { value: model.email.value, error: emailHasError, errorMessage: emailHasError ? 'E-postadressen är ej giltig' : '' },
    })
    if (emailHasError) {
      return
    }

    await onSendInterest()
    // return onModalClose?.()
  }

  return (
    <div>
      <Modal
        disableEnforceFocus
        open={isOpen}
        onClose={onModalCloseClick}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="analytics-modal modal-wrapper"
      >
        <div className="sign-up-modal">

          {!subscriptionIsVerified && (
            <h4 className="center-text">
              {getLanguageBasedText()}
            </h4>
          )}

          <br />
          {!subscriptionIsVerified ? (
            <form>
              <input
                className={`subscription-form__text ${model.email.error ? 'error' : ''}`}
                placeholder="E-mail"
                name="email"
                value={model.email.value}
                onChange={handleInputOnChange}
              />
              <span className="error-msg">{model.email.errorMessage}</span>

              <button type="button" className="action-button medium" onClick={subscribe}>Sign up</button>

            </form>
          )
            : (
              <div className="sign-up-thank-you">

                <h2>Thank you!</h2>
                <p className="registrerad">We will get back as soon as we can</p>
              </div>
            )}
        </div>
      </Modal>
    </div>
  )
}

export default LanguageModal
