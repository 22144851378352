import FireFlies from "components/FireFlies/FireFlies";
import SubscriberInput from "components/SubscriberInput/SubscriberInput";

export const NO_LONGER_ACTIVE_PAGE = "/no-longer-active"

const NoLongerActive = () => {
  return <>
    <div className="no-longer-active-page">
      <FireFlies />
      <div className="no-longer-active-page__content">
        <div className="no-longer-active-page__content__upper-section">
          Den här sidan är inte längre aktiv. Jiroy har förvandlats till något nytt och spektakulärt! Följ vår nya resa!
        </div>
        <div className="subscriber-input-wrapper" style={{ marginLeft: "auto", marginRight: "auto" }}>
          <SubscriberInput />
        </div>
      </div>
    </div>
  </>
};

export default NoLongerActive;
