const FireFlies = () => {
  return <>
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />

    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />

    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
    <div className="firefly" />
  </>
}

export default FireFlies